import * as lzstring from 'lz-string';

export function saveObjectToUrl(obj: Object) {
  // const data = b65encode(obj);
  const str = JSON.stringify(obj);
  const data = lzstring.compressToEncodedURIComponent(str);
  replaceStateQueryParameter('data', data);
}

export function loadObjectFromUrl(): Object {
  var params = new URLSearchParams(window.location.search);
  if (!params.has('data')) {
    return null;
  }
  const data = params.get('data');
  const str = lzstring.decompressFromEncodedURIComponent(data);
  return JSON.parse(str);
}

// Updates the query parameter of the current URL using replaceState API.
export function replaceStateQueryParameter(key: string, value: string) {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  window.history.replaceState(
      {}, '', decodeURIComponent(`${window.location.pathname}?${params}`));
}
