import Parameter from "../parameters/Parameter";

export default abstract class BaseModel {
  abstract deflate(): Object;
  abstract inflate(data: Object): void;

  getAllParameters(): Parameter<any>[] {
    // TODO: Better typing here.
    const obj = this as any;
    const keys = Object.getOwnPropertyNames(this);
    const props = keys.map(k => obj[k]);
    const params = props.filter(p => p instanceof Parameter);
    return params;
  }
}
