import * as chroma from 'chroma-js';
import * as Pixi from 'pixi.js';
import { IPixiSceneController } from '../../components/PixiApplication';
import { lerp } from '../../common/utils';
import { WhitneyModel } from './WhitneyModel';

const BALL_RADIUS = 8;
const HUE_RANGE = [150, 250];

export interface IPlayHandler {
  // Play an audio cue for the given ball index.
  play(index: number): void;
}

export class WhitneyScene implements IPixiSceneController {
  private model: WhitneyModel;
  private ballGraphics: Pixi.Graphics[];
  private playHandler: IPlayHandler;

  constructor(model: WhitneyModel, playHandler: IPlayHandler) {
    this.model = model;
    this.ballGraphics = [];
    this.playHandler = playHandler;
  }

  init(stage: Pixi.Container) {
    const ballContainer = stage.addChild(new Pixi.Container());

    // Create balls.
    for (let i = 0; i < this.model.numBalls.max; i++) {
      const ball = new Pixi.Graphics();
      ball.visible = false;
      this.ballGraphics.push(ball);
      ballContainer.addChild(ball);

      // Add an overlay graphics for play effect.
      ball.addChild(new Pixi.Graphics());
    }
    this.redrawBalls();

    // Playhead
    const playhead = new Pixi.Graphics();
    playhead.lineStyle(2, 0xffffff);
    playhead.moveTo(0, 0);
    playhead.lineTo(300, 0);
    ballContainer.addChild(playhead);

    // Event handling.
    this.model.numBalls.listen(() => this.redrawBalls());
  }

  dispose() { }

  // Reset position and visibility of balls.
  redrawBalls() {
    const numBalls = this.model.numBalls.value;
    const maxY = 300;
    for (let i = 0; i < this.model.numBalls.max; i++) {
      const ball = this.ballGraphics[i];
      ball.rotation = 0;
      if (i < numBalls) {
        const xPosition = (i + 1) / numBalls * maxY;
        const hue = lerp(HUE_RANGE[0], HUE_RANGE[1], i / numBalls);
        const color = chroma.hsl(hue, 0.8, 0.8);
        ball.visible = true;
        ball
          .clear()
          .beginFill(color.num())
          .drawCircle(xPosition, 0, BALL_RADIUS)
          .endFill();
        const overlay = ball.getChildAt(0) as Pixi.Graphics;
        overlay
          .clear()
          .beginFill(0xFFFFFF)
          .drawCircle(xPosition, 0, BALL_RADIUS)
          .endFill();
      } else {
        ball.visible = false;
      }
    }
  }

  // This update model is wrong some how, its not producing the descending
  // spinning arms approaching the big arm.  Its doing some really cool
  // shit though, so tomorrow lets:

  // - Add a switch for different spin algorithms other than whitney
  // - Pull other random variables i can see in code here out into the UI.
  // - Position shifting

  update(dt: number) {
    // Update rotations.
    const numBalls = this.model.numBalls.value;
    for (let i = 0; i < numBalls; i++) {
      const reverseIndex = numBalls - i;
      const ball = this.ballGraphics[i];
      const overlay = ball.getChildAt(0);
      const prev = ball.rotation;
      ball.rotation = (prev + this.normalizedSpeed() * reverseIndex * dt) % (Math.PI * 2)
      if (ball.rotation < prev) {
        this.playHandler.play(i);
      }
      overlay.alpha = lerp(1, 0, ball.rotation * 3);
    }
  }

  private normalizedSpeed(): number {
    return this.model.speed.value * 10 / this.model.numBalls.value;
  }
}
