import "antd/dist/antd.less";
import "./css/style.css";
import WhitneyModule from "./modules/whitney/WhitneyModule";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AudioManager from "./audio/audioManager";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import SpiderModule from "./modules/spider/SpiderModule";

// Run external initializers on static objects.
AudioManager.init()

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/whitney">
          <WhitneyModule />
        </Route>
        <Route path="/spider">
          <SpiderModule />
        </Route>
      </Switch>
    </Router>
  )
}

// Render the main router
const container = document.createElement('main');
document.body.appendChild(container);
ReactDOM.render(
  <MainRouter />,
  container,
);

