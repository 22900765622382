import Parameter from './Parameter';

class EnumParameter<T> extends Parameter<T[keyof T]> {
  private enumObject: T;

  constructor(enumObject: T, defaultValue: T[keyof T]) {
    super(defaultValue);
    this.enumObject = enumObject;
  }

  keys() {
    return Object.keys(this.enumObject).filter(key => isNaN(Number(key)));
  }

  valueAsEnumKey(): string {
    // HACK: There should be a better way to do this.
    return this.enumObject[this.value as unknown as keyof T] as unknown as string;
  }

  setFromEnumKey(key: keyof T) {
    this.value = this.enumObject[key];
  }
}

export default EnumParameter;
