import * as Pixi from 'pixi.js';
import * as React from 'react';

const DEFAULT_WIDTH = 800;
const DEFAULT_HEIGHT = 600;

export interface IPixiSceneController {
  init(container: Pixi.Container): void;
  dispose(): void;
  update(dt: number): void;
}

interface IProps {
  scene: IPixiSceneController;
}

export class PixiApplication extends React.Component<IProps> {
  private app: Pixi.Application
  private element: HTMLElement

  componentDidMount() {
    // The element is in the DOM, initialize pixi.    
    this.app = new Pixi.Application();
    this.element.appendChild(this.app.view);

    // Setup auto-resize.
    this.app.resizeTo = this.element;
    window.addEventListener('resize', () => this.resize());
    this.resize();

    // Inform the pixi view controller that we have a container ready.
    this.props.scene.init(this.app.stage);

    // Register an update loop with the shared ticker (uses RAF internally).
    this.app.ticker.add((dt) => this.props.scene.update(dt / 1000));
  }

  componentWillUnmount() {
    // The element is being removed from the DOM, teardown pixi.
    this.props.scene.dispose();
    this.app.stop();
  }

  shouldComponentUpdate() {
    // The pixi canvas is updated by the scene controller and never by react.
    return false;
  }

  render() {
    // Use react refs to hook into the DOM node directly.
    return (
      <div className="scene" ref={(element) => this.element = element} />
    )
  }

  resize() {
    this.app.resize();
    const halfWidth = this.app.renderer.width / 2;
    const halfHeight = this.app.renderer.height / 2;
    this.app.stage.position.set(halfWidth, halfHeight);
    const scale = this.app.renderer.width / DEFAULT_WIDTH;
    this.app.stage.scale.set(scale, scale);
  }
}
