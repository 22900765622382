import Parameter from './Parameter';
import { lerp } from '../common/utils';

interface Options {
  min?: number,
  max?: number,
  default?: number,
  wholeNumbers?: boolean,
}

class ScalarParameter extends Parameter<number> {
  readonly min: number;
  readonly max: number;
  readonly wholeNumbers: boolean;

  constructor(opts?: Options) {
    opts = opts || {};

    super(opts.default || opts.min || 0);
  
    this.min = opts.min || 0;
    this.max = opts.max || 1;
    this.wholeNumbers = !!opts.wholeNumbers;
  }

  // Return the paramter normalized to 0-1.
  normalized(): number {
    const range = this.max - this.min;
    return (this.value - this.min) / range;
  }

  // Return the parameter rescaled in a different range.
  lerp(min: number, max: number) {
    return lerp(min, max, this.normalized());
  }
}

export default ScalarParameter;
