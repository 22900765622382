type Listener<T> = (value: T) => void;

abstract class Parameter<T> {
  private _value: T
  private readonly defaultValue: T
  private onChangeListeners: Listener<T>[];

  constructor(defaultValue: T) {
    this.onChangeListeners = [];
    this._value = this.defaultValue = defaultValue;
  }

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
    this.notify();
  }

  reset() {
    this.value = this.defaultValue;
  }
  
  listen(listener: Listener<T>, triggerImmediate?: boolean) {
    this.onChangeListeners.push(listener);
    if (triggerImmediate) {
      listener(this.value);
    }
  }

  private notify() {
    for (const listener of this.onChangeListeners) {
      listener(this.value);
    }
  }

  static listenAll(params: Parameter<any>[], listener: Listener<any>) {
    for (const p of params) {
      p.listen(listener);
    }
  }
}

export default Parameter;
