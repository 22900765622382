import * as React from 'react';
import ParameterControls from '../../components/ParameterControls';
import { PixiApplication } from '../../components/PixiApplication';
import { WhitneyModel } from './WhitneyModel';
import { WhitneyScene } from './WhitneyScene';

interface Props {
  model: WhitneyModel,
  scene: WhitneyScene,
}

export class WhitneyView extends React.Component<Props> {
  render() {
    return (
      <div className="module-container">
        <div className="module-panel">
          <ParameterControls label="Ball count" param={this.props.model.numBalls} />
          <ParameterControls label="Speed" param={this.props.model.speed} />
          <ParameterControls label="Pitch" param={this.props.model.pitch} />
          <ParameterControls label="Note length" param={this.props.model.decay} />
          <ParameterControls label="Direction" param={this.props.model.scaleDirection} />
          <ParameterControls label="Scale" param={this.props.model.scaleType} />
          <ParameterControls label="Chord" param={this.props.model.chord} />
          <ParameterControls label="Sequence" param={this.props.model.sequence} />
          <div className="expander"></div>
          <div>
            Inspired by the <a href="http://whitneymusicbox.org/index.php?var=v11" target="_blank">
              original Whitney's Music box
            </a>
          </div>
        </div>

        <div className="module-content">
          <PixiApplication scene={this.props.scene}>
          </PixiApplication>
        </div>
      </div>
    );
  }
}
