import Parameter from './Parameter';

interface Options {
  maxLength?: number;
  default?: string,
  textArea?: boolean,
}

class StringParameter extends Parameter<string> {
  readonly maxLength: number;
  readonly textArea: boolean;

  constructor(opts?: Options) {
    opts = opts || {};
  
    super(opts.default || "");

    this.maxLength = opts.maxLength || 0;
    this.textArea = !!opts.textArea;
  }
}

export default StringParameter;
