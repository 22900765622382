import * as Tone from 'tone';
import * as Chance from 'chance';
import SynthPool from './SynthPool';

const c = new Chance;

// Bad singleton for now, will fix later
class AudioManager {
  // TODO - Params for these controls. duh.
  static master: Tone.Volume;
  static verb: Tone.JCReverb;
  static delay: Tone.FeedbackDelay;
  static synth: Tone.Instrument | SynthPool;

  constructor() {

  }

 static init() {
    // Configure global tone.js settings for low latency.
    // TODO: Use a better syncing solution: https://github.com/Tonejs/Tone.js/wiki/Performance#syncing-visuals
    // Tone.context.lookAhead = 0.1;
    Tone.context.latencyHint = 'fastest';

    // Pretty hacky.
    const context = Tone.context as unknown as AudioContext;
    if (context.state !== 'running') {
      context.resume();
    }

    // Create output chain.
    this.master = new Tone.Volume(-30).toMaster();
    this.verb = new Tone.JCReverb(0.2).connect(this.master);
    this.delay = new Tone.FeedbackDelay(0.4, 0.4).connect(this.verb)

    // Create voice pool.
    this.synth = new SynthPool(64, this.master, buildSynth);
  }

  static debugPlay(freq: number, decay: number) {
    this.synth.triggerAttackRelease(freq, decay);
  }
}

export default AudioManager;

const buildSynth = (): Tone.Instrument => {
  return new Tone.Synth({
    oscillator: {
      type: "triangle",
    },
  })
  // return new Tone.FMSynth({
  //   envelope: {
  //     attack: 0.005,
  //     decay: 0,
  //     sustain: 0,
  //     release: 0.5,
  //   },
  //   modulationEnvelope: {
  //     attack: 0.005,
  //   },
  // })
};
