import * as React from 'react';
import EnumParameter from '../parameters/EnumParameter';
import Parameter from '../parameters/Parameter';
import ScalarParameter from '../parameters/ScalarParameter';
import StringParameter from '../parameters/StringParameter';
import {
  Col,
  Input,
  Radio,
  Row,
  Slider
} from 'antd';

/**
 * Public interface, a component which accepts any param type.
 */

interface Props {
  label: string,
  param: Parameter<any>,
}

const ParameterControls = (props: Props) => {
  var element: JSX.Element;
  if (props.param instanceof ScalarParameter) {
    element = <ScalarControl param={props.param as ScalarParameter} />
  } else if (props.param instanceof EnumParameter) {
    element = <EnumControl param={props.param as EnumParameter<any>} />
  } else if (props.param instanceof StringParameter) {
    element = <StringControl param={props.param as StringParameter} />
  } else {
    element = <div>Error</div>
  }

  return (
    <Row>
      <Col span={8}>{props.label}</Col>
      <Col span={16}>
        {element}
      </Col>
    </Row>
  );
}

/**
 * Scalar.
 */

interface ScalarControlProps {
  param: ScalarParameter,
}

class ScalarControl extends React.Component<ScalarControlProps> {
  render() {
    return <Slider
      min={this.props.param.min}
      max={this.props.param.max}
      step={this.props.param.wholeNumbers ? 1 : 0.01}
      defaultValue={this.props.param.value}
      tooltipVisible={false}
      onChange={(value) => this.onChange(value as number)}
    />
  }

  onChange(value: number) {
    this.props.param.value = value;
  }
}

/**
 * Enum.
 */

interface EnumControlProps<T> {
  param: EnumParameter<T>,
}

class EnumControl<T> extends React.Component<EnumControlProps<T>> {
  render() {
    const buttons = this.props.param.keys().map(k => {
      return <Radio.Button value={k} key={k}>{k}</Radio.Button>
    });
    return <Radio.Group
      defaultValue={this.props.param.valueAsEnumKey()}
      onChange={(e) => this.props.param.setFromEnumKey(e.target.value)}
      buttonStyle="solid">
      {buttons}
    </Radio.Group>
  }
}

/**
 * String input.
 */

interface StringControlProps {
  param: StringParameter,
}

class StringControl extends React.Component<StringControlProps> {
  render() {
    if (this.props.param.textArea) {
      return <Input.TextArea
        defaultValue={this.props.param.value}
        onChange={(e) => this.props.param.value = e.target.value}
        maxLength={this.props.param.maxLength || null}
      />
    }
    return <Input
      defaultValue={this.props.param.value}
      onChange={(e) => this.props.param.value = e.target.value}
      maxLength={this.props.param.maxLength || null}
    />
  }
}

export default ParameterControls;
