export default class DebouncedCallback {
  private delay: number;
  private callback: Function;
  private timeoutHandle?: number;

  constructor(delay: number, callback: Function) {
    this.delay = delay;
    this.callback = callback;
    this.timeoutHandle = null;
  }

  fire() {
    if (this.timeoutHandle != null) {
      clearTimeout(this.timeoutHandle);
    }
    this.timeoutHandle = setTimeout(this.callback, this.delay);
  }
}
