import AudioManager from '../../audio/audioManager';
import Sequence from '../../audio/Sequence';
import { IPlayHandler } from './WhitneyScene';
import { mtof } from '../../audio/utils';
import { ScaleDirection, ScaleType, WhitneyModel } from './WhitneyModel';

const pentaSeq = new Sequence([0, 3, 5, 7, 10]);

export default class WhitneyAudioController implements IPlayHandler {
  private model: WhitneyModel;
  private chordSeq: Sequence;
  private customSeq: Sequence;

  constructor(model: WhitneyModel) {
    this.model = model;
    this.chordSeq = this.customSeq = new Sequence([0]);

    // Listen to events.
    this.model.chord.listen((chordName) => this.onChordChanged(chordName), true);
    this.model.sequence.listen((value) => this.onSequenceChanged(value), true);
  }

  onChordChanged(chordName: string) {
    const seq = Sequence.fromChord(chordName);
    if (seq.isValid()) {
      this.chordSeq = seq;
    }
  }

  onSequenceChanged(noteNamesRaw: string) {
    const noteNames = noteNamesRaw.split(',').map(n => n.trim());
    const seq = Sequence.fromNoteNames(noteNames);
    if (seq.isValid()) {
      this.customSeq = seq;
    }
  }

  play(index: number) {
    const playIndex =
      this.model.scaleDirection.value == ScaleDirection.UP ?
        index : this.model.numBalls.value - index;
    const decay = this.model.decay.value;
    let freq = 100;
    let step = this.model.pitch.lerp(30, 80) + playIndex;
    let base = this.model.pitch.lerp(330, 550);
    switch (this.model.scaleType.value) {
      case ScaleType.TET:
        freq = mtof(step);
        break;
      case ScaleType.HARMONIC:
        freq = this.model.pitch.value / 10 * playIndex;
        break;
      case ScaleType.MICROTONAL:
        freq = mtof(step, 0, 19);
        break;
      case ScaleType.PENTATONIC:
        step = this.model.pitch.lerp(30, 80) + pentaSeq.get(playIndex);
        freq = mtof(step);
        break;
      case ScaleType.CHORD:
        step = this.chordSeq.get(playIndex);
        freq = mtof(step, 1, undefined, base);
        break;
      case ScaleType.SEQUENCE:
        step = this.customSeq.get(playIndex);
        freq = mtof(step, undefined, undefined, base);
        break;
      default:
        break;
    }
    AudioManager.debugPlay(freq, decay);
  }
}
