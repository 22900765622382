import EnumParameter from '../../parameters/EnumParameter';
import ScalarParameter from '../../parameters/ScalarParameter';
import StringParameter from '../../parameters/StringParameter';
import BaseModel from '../../common/BaseModel';

export enum ScaleDirection {
  UP,
  DOWN,
}

export enum ScaleType {
  TET,
  HARMONIC,
  MICROTONAL,
  PENTATONIC,
  CHORD,
  SEQUENCE,
}

export class WhitneyModel extends BaseModel {
  numBalls: ScalarParameter;
  speed: ScalarParameter;
  pitch: ScalarParameter;
  decay: ScalarParameter;
  scaleDirection: EnumParameter<typeof ScaleDirection>;
  scaleType: EnumParameter<typeof ScaleType>;
  chord: StringParameter;
  sequence: StringParameter;

  constructor() {
    super();

    this.numBalls = new ScalarParameter({
      min: 3,
      max: 100,
      default: 30,
      wholeNumbers: true,
    });
    this.speed = new ScalarParameter({
      min: 0.1,
      max: 30,
      default: 8,
    });
    this.pitch = new ScalarParameter({
      min: 100,
      max: 1000,
      default: 500,
      wholeNumbers: true,
    });
    this.decay = new ScalarParameter({
      default: 0.1,
      min: 0.01,
      max: 0.5,
    });
    this.scaleDirection = new EnumParameter(ScaleDirection, ScaleDirection.UP);
    this.scaleType = new EnumParameter(ScaleType, ScaleType.TET);
    this.chord = new StringParameter({
      maxLength: 16,
      default: "Bbmaj9",
    });
    this.sequence = new StringParameter({
      textArea: true,
      default: "D4, E4, A#4, C3, D5, Bb2, F2, G2",
    });
  }

  deflate(): Object {
    // TODO: Infer these keys automatically somehow?
    return {
      c: this.chord.value,
      d: this.decay.value,
      nb: this.numBalls.value,
      p: this.pitch.value,
      sd: this.scaleDirection.value,
      st: this.scaleType.value,
      sq: this.sequence.value,
      sp: this.speed.value,
    };
  }

  inflate(data: any) {
    // TODO: Infer these keys automatically somehow?
    this.chord.value = data.c;
    this.decay.value = data.d;
    this.numBalls.value = data.nb;
    this.pitch.value = data.p;
    this.scaleDirection.value = data.sd;
    this.scaleType.value = data.st;
    this.sequence.value = data.sq;
    this.speed.value = data.sp;
  }
}
