import { Instrument, Encoding, ProcessingNode } from "tone";

export default class SynthPool {
  private pool: Instrument[]
  private index: number = 0

  constructor(voices: number, output: ProcessingNode, builder: () => Instrument) {
    this.pool = new Array(voices);
    for (let i = 0; i < voices; i++) {
      this.pool[i] = builder().connect(output);
    }
  }

  /**
   * Trigger the attack and then the release after the duration
   */
  triggerAttackRelease(
    note: Encoding.Frequency,
    duration: Encoding.Time,
    time?: Encoding.Time,
    velocity?: Encoding.NormalRange
  ) {
    this.pool[this.index].triggerAttackRelease(note, duration, time, velocity);
    this.index = (this.index + 1) % this.pool.length;
  }
}
