import { toMidi } from '@tonaljs/midi';
import { parseChordAbsolute } from './utils';

// Repeating note sequence abstraction with octaves.
export default class Sequence {
  notes: Array<number>;

  constructor(notes: Array<number>) {
    this.notes = notes;
  }

  get(index: number) {
    const val = this.notes[index % this.notes.length];
    const octave = Math.floor(index / this.notes.length);
    return val + octave * 12;
  }

  isValid() {
    return this.notes.length > 0 &&
           this.notes.every(n => !isNaN(n) && n > 0);
  }

  static fromNoteNames(noteNames: Array<string>) {
    return new Sequence(noteNames.map(toMidi));
  }

  static fromChord(chordName: string) {
    const notes = parseChordAbsolute(chordName);
    return Sequence.fromNoteNames(notes);
  }
}
