import * as React from 'react';
import BaseModule from '../BaseModule';
import DebouncedCallback from '../../common/DebouncedCallback';
import Parameter from '../../parameters/Parameter';
import WhitneyAudioController from './WhitneyAudioController';
import { WhitneyModel } from './WhitneyModel';
import { saveObjectToUrl, loadObjectFromUrl } from '../../common/url';
import { WhitneyScene } from './WhitneyScene';
import { WhitneyView } from './WhitneyView';

interface Props {}

export default class WhitneyModule extends BaseModule<Props> {
  private model: WhitneyModel;
  private scene: WhitneyScene;
  private audioController: WhitneyAudioController;

  constructor(props: Props) {
    super(props);

    // Setup main components.
    this.model = new WhitneyModel();
    this.audioController = new WhitneyAudioController(this.model);
    this.scene = new WhitneyScene(this.model, this.audioController);

    // Setup model save.
    // TODO: This should be abstracted better.
    const saveCallback = new DebouncedCallback(200, () => this.saveModel());
    Parameter.listenAll(this.model.getAllParameters(), () => saveCallback.fire());

    // Restore the model if a serialized state is in the URL.
    this.tryRestoreModel();
  }

  render() {
    return (
      <WhitneyView model={this.model} scene={this.scene} />
    )
  }

  private saveModel() {
    saveObjectToUrl(this.model.deflate());
  }
  
  private tryRestoreModel() {
    // TODO: Error handling and validation here obviously.
    const data = loadObjectFromUrl();
    if (data != null) {
      this.model.inflate(data);
    }
  }
}
