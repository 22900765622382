// Interface that matches the JS generated by typescript enums.
export interface EnumLike {
  [key: number]: string
}

export const getEnumKeys = (E: EnumLike) => {
  return Object.keys(E).filter(key => isNaN(Number(key)));
}

export const clamp = (val: number, min: number, max: number) => {
  return Math.max(Math.min(val, max), min);
}

export const lerp = (a: number, b: number, t: number) => {
  const raw = a + (b - a) * t;
  if (b > a) {
    return clamp(raw, a, b);
  }
  return clamp(raw, b, a);
}
