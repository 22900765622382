import { chord, transpose } from "@tonaljs/chord";

// Convert a halfstep (i.e. midi) value into its absolute equal tempered frequency.
export const mtof = (
    step: number, octave: number = 0, subdivisions: number = 12,
    basePitch: number = 440) => {
  step = step + octave * subdivisions;
  // TODO: Handle non-12-tone base better.
  return basePitch * Math.pow(2, (step - 69) / subdivisions);
  // return Math.pow(2, step / subdivisions) * basePitch;
};

// Parse a chord name into absolute note names rooted at the 0th octave.
export const parseChordAbsolute = (chordName: string, octave: number = 0) => {
  const c = chord(chordName);
  const root = `${c.notes[0]}${octave}`;
  return c.intervals.map((int) => transpose(root, int));
};
